import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { SmallSolidTealButton } from '../../utils/styles'
import LocalizedLink from '../../components/localized-link'
import ModalVideo from 'react-modal-video'
import { Link } from 'gatsby'
import Slider from 'react-slick'

const MostPopularWrapper = styled.div`
  .popular-slider {
    margin: 30px -25px 0 -25px;
    padding-bottom: 75px;
    .slick-item {
      padding: 0 5px;
    }
  }
  .popular-dots {
    bottom: -15px;
  }
  .desktop-dots {
    bottom: -50px;
  }
  .popular-slider-desktop {
    display: flex !important;
  }
`

const MostPopular = ({ resourcesList, i18n }) => {
  const [isOpen, setOpen] = useState(false)
  const [videoItem, setVideoItem] = useState(null)
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb popular-dots',
    infinite: true,
    speed: 500,
    autoplay: true,
    centerMode: true,
    centerPadding: '5%',
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const desktopSettings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb desktop-dots',
    infinite: true,
    speed: 500,
    autoplay: true,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  }
  return resourcesList.length > 0 ? (
    <MostPopularWrapper tw="container pt-20 pb-8 md:pt-28 md:pb-14">
      <h2 tw="text-navy">{i18n.resources.most_popular.headline}</h2>
      <div tw="hidden md:grid grid-cols-2 mt-8 rounded-lg">
        <div tw="rounded-t-lg md:rounded-t-none md:rounded-l-lg relative">
          {resourcesList[0].data.category === 'Papers' ||
          resourcesList[0].data.category === 'News' ||
          resourcesList[0].data.category === 'Videos' ? (
            resourcesList[0].data.category === 'Videos' ? (
              <div
                onClick={() => {
                  setOpen(true)
                  setVideoItem(resourcesList[0])
                }}
                tw="cursor-pointer">
                <Img
                  fluid={resourcesList[0].data.image.fluid}
                  tw="rounded-t-lg md:rounded-t-none md:rounded-l-lg object-cover max-h-96"
                />
              </div>
            ) : (
              <Link to={resourcesList[0].data.link.url} target="_blank">
                <Img
                  fluid={resourcesList[0].data.image.fluid}
                  tw="rounded-t-lg md:rounded-t-none md:rounded-l-lg object-cover max-h-96"
                />
              </Link>
            )
          ) : (
            <LocalizedLink to={`/resource/${resourcesList[0].uid}`}>
              <Img
                fluid={resourcesList[0].data.image.fluid}
                tw="rounded-t-lg md:rounded-t-none md:rounded-l-lg object-cover max-h-96"
              />
            </LocalizedLink>
          )}
          <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
            {resourcesList[0].data.category}
          </div>
        </div>
        <div tw="md:flex items-center bg-air py-14 px-16 md:flex-grow rounded-b-lg md:rounded-b-none md:rounded-r-lg">
          <div>
            <h4 tw="text-navy">{resourcesList[0].data.meta_title}</h4>
            <p tw="text-ocean mt-4">{resourcesList[0].data.meta_description}</p>

            {resourcesList[0].data.category === 'Papers' ||
            resourcesList[0].data.category === 'News' ||
            resourcesList[0].data.category === 'Videos' ? (
              resourcesList[0].data.category === 'Videos' ? (
                <div
                  onClick={() => {
                    setOpen(true)
                    setVideoItem(resourcesList[0])
                  }}
                  tw="cursor-pointer">
                  <SmallSolidTealButton tw="mt-5">
                    {i18n.resources.most_popular.button}
                  </SmallSolidTealButton>
                </div>
              ) : (
                <Link to={resourcesList[0].data.link.url} target="_blank">
                  <SmallSolidTealButton tw="mt-5">
                    {i18n.resources.most_popular.button}
                  </SmallSolidTealButton>
                </Link>
              )
            ) : (
              <LocalizedLink to={`/resource/${resourcesList[0].uid}`}>
                <SmallSolidTealButton tw="mt-5">
                  {i18n.resources.most_popular.button}
                </SmallSolidTealButton>
              </LocalizedLink>
            )}
          </div>
        </div>
      </div>
      <Slider {...desktopSettings} tw="hidden md:block mt-16">
        {resourcesList.slice(1).map((item, key) => {
          return (
            <div
              key={key}
              tw="flex flex-row items-center"
              className="popular-slider-desktop">
              <div tw="flex-none h-16">
                {item.data.category === 'Papers' ||
                item.data.category === 'News' ||
                item.data.category === 'Videos' ? (
                  item.data.category === 'Videos' ? (
                    <div
                      onClick={() => {
                        setOpen(true)
                        setVideoItem(item)
                      }}
                      tw="cursor-pointer">
                      <Img
                        fluid={item.data.image.fluid}
                        tw="w-24 h-16 rounded-lg object-cover"></Img>
                    </div>
                  ) : (
                    <Link to={item.data.link.url} target="_blank">
                      <Img
                        fluid={item.data.image.fluid}
                        tw="w-24 h-16 rounded-lg object-cover"></Img>
                    </Link>
                  )
                ) : (
                  <LocalizedLink to={`/resource/${item.uid}`}>
                    <Img
                      fluid={item.data.image.fluid}
                      tw="w-24 h-16 rounded-lg object-cover"></Img>
                  </LocalizedLink>
                )}
              </div>
              <div tw="flex-grow px-6">
                {item.data.category === 'Papers' ||
                item.data.category === 'News' ||
                item.data.category === 'Videos' ? (
                  item.data.category === 'Videos' ? (
                    <div
                      onClick={() => {
                        setOpen(true)
                        setVideoItem(item)
                      }}
                      tw="cursor-pointer">
                      <h5 tw="text-navy" className="article-title">
                        {item.data.meta_title}
                      </h5>
                    </div>
                  ) : (
                    <Link to={item.data.link.url} target="_blank">
                      <h5 tw="text-navy" className="article-title">
                        {item.data.meta_title}
                      </h5>
                    </Link>
                  )
                ) : (
                  <LocalizedLink to={`/resource/${item.uid}`}>
                    <h5 tw="text-navy" className="article-title">
                      {item.data.meta_title}
                    </h5>
                  </LocalizedLink>
                )}
              </div>
            </div>
          )
        })}
      </Slider>
      <Slider {...settings} tw="md:hidden mt-8" className="popular-slider">
        {resourcesList.map((item, key) => {
          return (
            <div key={key} className="slick-item">
              <div tw="relative">
                {item.data.category === 'Papers' ||
                item.data.category === 'News' ||
                item.data.category === 'Videos' ? (
                  item.data.category === 'Videos' ? (
                    <div
                      onClick={() => {
                        setOpen(true)
                        setVideoItem(item)
                      }}
                      tw="cursor-pointer">
                      <Img
                        fluid={item.data.image.fluid}
                        tw="rounded-t-lg h-60 object-cover"></Img>
                    </div>
                  ) : (
                    <Link to={item.data.link.url} target="_blank">
                      <Img
                        fluid={item.data.image.fluid}
                        tw="rounded-t-lg h-60 object-cover"></Img>
                    </Link>
                  )
                ) : (
                  <LocalizedLink to={`/resource/${item.uid}`}>
                    <Img
                      fluid={item.data.image.fluid}
                      tw="rounded-t-lg h-60 object-cover"></Img>
                  </LocalizedLink>
                )}
                <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white">
                  {item.data.category}
                </div>
              </div>
              <div tw="bg-air py-8 px-8 md:flex-grow rounded-b-lg h-80">
                <h4 tw="text-navy">{item.data.meta_title}</h4>
                <p tw="text-ocean mt-4">
                  {resourcesList[0].data.meta_description}
                </p>
                {item.data.category === 'Papers' ||
                item.data.category === 'News' ||
                item.data.category === 'Videos' ? (
                  item.data.category === 'Videos' ? (
                    <div
                      onClick={() => {
                        setOpen(true)
                        setVideoItem(item)
                      }}
                      tw="cursor-pointer">
                      <SmallSolidTealButton tw="mt-5">
                        {i18n.resources.most_popular.button}
                      </SmallSolidTealButton>
                    </div>
                  ) : (
                    <Link to={item.data.link.url} target="_blank">
                      <SmallSolidTealButton tw="mt-5">
                        {i18n.resources.most_popular.button}
                      </SmallSolidTealButton>
                    </Link>
                  )
                ) : (
                  <LocalizedLink to={`/resource/${item.uid}`}>
                    <SmallSolidTealButton tw="mt-5">
                      {i18n.resources.most_popular.button}
                    </SmallSolidTealButton>
                  </LocalizedLink>
                )}
              </div>
            </div>
          )
        })}
      </Slider>
      {videoItem != null &&
      videoItem.data.category === 'Videos' &&
      videoItem.data.link.url != '' ? (
        <div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId={videoItem.data.link.url.substring(
              videoItem.data.link.url.lastIndexOf('=') + 1,
              videoItem.data.link.url.length
            )}
            youtube={{
              autoplay: 1,
              mute: 1,
            }}
            onClose={() => {
              setOpen(false)
              setVideoItem(null)
            }}
          />
        </div>
      ) : (
        false
      )}
    </MostPopularWrapper>
  ) : (
    false
  )
}
export default MostPopular
