import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { useForm } from 'react-hook-form'
import background from '../../images/resources_bg.svg'
import paperPlane from '../../images/paper-plane.svg'
import axios from 'axios'
import { SmallSolidTurquoiseInput } from '../../utils/styles'
const HeroWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 138px 0;
  @media (max-width: 640px) {
    padding-bottom: 60px;
  }
`

const HeroForm = ({ resources, i18n }) => {
  const [subscribed, setSubscribed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/subscribe', {
      form_id: '108139882',
      email: data.email,
    })
    if (response.data.hasOwnProperty('id')) {
      setSubscribed(true)
    }
    setSubmitting(false)
  }
  return (
    <HeroWrapper>
      <div tw="container">
        <div tw="grid md:grid-cols-4 md:px-16 ">
          <div tw="md:flex items-center">
            <img src={paperPlane} tw="w-48 m-auto md:m-0" />
          </div>
          <div tw="md:col-span-2">
            <form
              onSubmit={handleSubmit(onSubmit)}
              tw="flex flex-col gap-4 text-center">
              <h2 tw="text-navy">
                {!subscribed
                  ? resources.data.subscribe_form_headline.text
                  : i18n.subscribe.message.headline}
              </h2>
              <p tw="max-w-md text-ocean m-auto">
                {!subscribed
                  ? resources.data.subscribe_form_subheadline.text
                  : i18n.subscribe.message.desc}
              </p>
              {!subscribed ? (
                <div tw="text-left">
                  <div tw="flex flex-col md:flex-row gap-y-4">
                    <input
                      {...register('email', {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                      placeholder={i18n.subscribe.email}
                      tw="py-3.5 md:py-0 px-5 text-ocean rounded md:w-96"
                    />

                    <SmallSolidTurquoiseInput
                      type="submit"
                      tw="relative md:text-lg"
                      className={submitting ? 'button is-loading' : 'button'}
                      disabled={submitting}>
                      {i18n.buttons.subscribe}
                    </SmallSolidTurquoiseInput>
                  </div>
                  {errors.email?.type === 'required' && (
                    <span tw="block mt-2 text-red">Email is required</span>
                  )}
                  {errors.email?.type === 'pattern' && (
                    <span tw="block mt-2 text-red">Invalid email address</span>
                  )}
                </div>
              ) : (
                false
              )}
            </form>
          </div>
        </div>
      </div>
    </HeroWrapper>
  )
}
export default HeroForm
