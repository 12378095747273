import React from 'react'
import tw, { styled } from 'twin.macro'
import i18n from '../../../config/i18n'
import search from '../../images/search.svg'

const SearchWrapper = styled.div`
  input {
    background: #edf4fb;
    border-radius: 83px;
    height: 59px;
    color: #1d5a80;
    padding: 17px 33px;
    @media (min-width: 641px) {
      width: 640px;
    }
    @media (max-width: 640px) {
      width: 100%;
    }
  }
  img {
    margin-left: -85px;
  }
`

const Search = ({ handleSearch, i18n }) => {
  return (
    <SearchWrapper tw="mt-8 flex justify-center">
      <input
        placeholder={i18n.resources.search.enter}
        onChange={handleSearch}
      />
      <img src={search} />
    </SearchWrapper>
  )
}
export default Search
