import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import WatchElementAnimate from '../../components/watch-element-animate'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Search from './search'
import axios from 'axios'
import LocalizedLink from '../../components/localized-link'
import { SubForm, SmallSolidTurquoiseInput } from '../../utils/styles'
import { useForm } from 'react-hook-form'
import ModalVideo from 'react-modal-video'
import Send from '../../images/Send.svg'
import { SmallSolidTealButton } from '../../utils/styles'
import * as JsSearch from 'js-search'

const ResourcesWrapper = styled.div`
  .category-filter {
    .filter-item {
      line-height: 59px;
      padding: 0 30px;
    }
    .active {
      background: #007e9f;
      box-shadow: 0px 19px 29px rgba(0, 126, 159, 0.159601);
      border-radius: 29.5px;
      color: #fff;
    }
  }
  .resource-item {
    border: solid 1px #edf4fb;
    border-radius: 8px;
  }
`

const Resources = ({ resourcesList, i18n }) => {
  const [activeCategory, setActiveCategory] = useState('All')
  const [search, setSearch] = useState([])
  const [searchResults, setSearchResults] = useState(
    resourcesList.map((r) => {
      return {
        title: r.data.meta_title,
        description: r.data.meta_description,
        category: r.data.category,
        html: r.data.content.html,
        image: r.data.image,
        link: r.data.link,
        uid: r.uid,
      }
    })
  )
  const [more, setMore] = useState(8)
  const [subscribed, setSubscribed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [videoItem, setVideoItem] = useState(null)
  const { register, handleSubmit } = useForm()
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/subscribe', {
      form_id: '108139882',
      email: data.email,
    })
    if (response.data.hasOwnProperty('id')) {
      setSubscribed(true)
    }
    setSubmitting(false)
  }
  useEffect(() => {
    const dataToSearch = new JsSearch.Search('uid')
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    dataToSearch.addIndex('title')
    dataToSearch.addIndex('description')
    dataToSearch.addIndex('category')
    dataToSearch.addIndex('html')
    dataToSearch.addDocuments(searchResults)
    setSearch(dataToSearch)
  }, [])

  // Don't delete! This code implements a category header to filter the item grid. 
  // This is currently not needed, since we only use one category. 
  // const category = [
  //   { value: 'All', label: i18n.resources.category.all },
  //   { value: 'Blog post', label: i18n.resources.category.blog },
  //   { value: 'Papers', label: 'Papers' },
  //   { value: 'News', label: 'News' },
  //   { value: 'Templates', label: 'Templates' },
  //   { value: 'Videos', label: 'Videos' },
  //   { value: 'Inforgraphics', label: i18n.resources.category.infographics },
  // ]
  // const options = category
  // const handleChange = (selectedOption) => {
  //   setActiveCategory(selectedOption.value)
  // }
  // const handleCategoryClick = (c) => {
  //   setActiveCategory(c)
  // }
  const handleSearch = (e) => {
    if (e.target.value != '') {
      const result = search.search(e.target.value)
      setSearchResults(result)
    } else {
      setSearchResults(
        resourcesList.map((r) => {
          return {
            title: r.data.meta_title,
            description: r.data.meta_description,
            category: r.data.category,
            html: r.data.content.html,
            image: r.data.image,
            link: r.data.link,
            uid: r.uid,
          }
        })
      )
    }
  }
  const filteredResourcesList =
    activeCategory === 'All'
      ? searchResults
      : searchResults.filter((r) => r.category === activeCategory)
  return (
    <ResourcesWrapper tw="container pt-20 pb-8 text-center md:pt-28 md:pb-8">
      <WatchElementAnimate
        className={'.resources__resources__animated__head'}
        animateName={'animate__fadeInUp'}>
        <div className="resources__resources__animated__head">
          <h2 tw="text-navy">{i18n.resources.search.title}</h2>
          <Search handleSearch={handleSearch} i18n={i18n} />
        </div>
      </WatchElementAnimate>
      <WatchElementAnimate
        className={'.resources__resources__animated__body'}
        animateName={'animate__fadeInUp'}>
        <div className="resources__resources__animated__body">
          {/* 
          Please don't delete! This code implements a category header to filter the item grid. 
          This is currently not needed, since we only use one category. 
          <div
            tw="hidden md:flex flex-col md:flex-row justify-between mt-14"
            className="category-filter">
            {category.map((c, key) => {
              return (
                <div
                  tw="text-teal text-lg font-semibold cursor-pointer"
                  className={
                    c.value === activeCategory
                      ? 'active filter-item'
                      : 'filter-item'
                  }
                  onClick={() => handleCategoryClick(c.value)}
                  key={key}>
                  <span>{c.label}</span>
                </div>
              )
            })}
          </div>
          <div tw="md:hidden text-left">
             <Select
              options={options}
              value={{ value: activeCategory, label: activeCategory }}
              onChange={handleChange}
              className="q-select"
              classNamePrefix="q"
            /> 
          </div>*/}
          <div tw="grid md:grid-cols-3 mt-10 gap-8">
            {filteredResourcesList.slice(0, 5).map((r, key) => {
              return r.category === 'Papers' ||
                r.category === 'News' ||
                r.category === 'Videos' ? (
                r.category === 'Videos' ? (
                  <div
                    onClick={() => {
                      setOpen(true)
                      setVideoItem(r)
                    }}
                    tw="cursor-pointer">
                    <div key={key} className="resource-item">
                      <div tw="relative">
                        <Img
                          fluid={r.image.fluid}
                          tw="rounded-t-lg object-cover h-64"
                        />
                        <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                          {r.category}
                        </div>
                      </div>
                      <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                        <p tw="text-navy">{r.title}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={r.link.url} target="_blank">
                    <div key={key} className="resource-item">
                      <div tw="relative">
                        <Img
                          fluid={r.image.fluid}
                          tw="rounded-t-lg object-cover h-64"
                        />
                        <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                          {r.category}
                        </div>
                      </div>
                      <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                        <p tw="text-navy">{r.title}</p>
                      </div>
                    </div>
                  </Link>
                )
              ) : (
                <LocalizedLink to={`/resource/${r.uid}`}>
                  <div key={key} className="resource-item">
                    <div tw="relative">
                      <Img
                        fluid={r.image.fluid}
                        tw="rounded-t-lg object-cover h-64"
                      />
                      <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                        {r.category}
                      </div>
                    </div>
                    <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                      <p tw="text-navy">{r.title}</p>
                    </div>
                  </div>
                </LocalizedLink>
              )
            })}
            <SubForm tw="w-full">
              <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col">
                <img src={Send} tw="w-6 m-auto" />
                <h4 tw="text-center mt-6">
                  {!subscribed
                    ? i18n.subscribe.headline
                    : i18n.subscribe.message.headline}
                </h4>
                <p tw="text-center mt-4 mb-6">
                  {!subscribed
                    ? i18n.subscribe.desc
                    : i18n.subscribe.message.desc}
                </p>
                {!subscribed ? (
                  <input
                    {...register('email', { required: true })}
                    placeholder={i18n.subscribe.email}
                    className="q-input"
                  />
                ) : (
                  false
                )}
                {!subscribed ? (
                  <SmallSolidTurquoiseInput
                    type="submit"
                    tw="relative md:text-lg"
                    className={submitting ? 'button is-loading' : 'button'}
                    disabled={submitting}>
                    {i18n.subscribe.button}
                  </SmallSolidTurquoiseInput>
                ) : (
                  false
                )}
              </form>
            </SubForm>
            {filteredResourcesList.slice(5, more).map((r, key) => {
              return r.category === 'Papers' ||
                r.category === 'News' ||
                r.category === 'Videos' ? (
                r.category === 'Videos' ? (
                  <div
                    onClick={() => {
                      setOpen(true)
                      setVideoItem(r)
                    }}
                    tw="cursor-pointer">
                    <div key={key} className="resource-item">
                      <div tw="relative">
                        <Img
                          fluid={r.image.fluid}
                          tw="rounded-t-lg object-cover h-64"
                        />
                        <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                          {r.category}
                        </div>
                      </div>
                      <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                        <p tw="text-navy">{r.title}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={r.link.url} target="_blank">
                    <div key={key} className="resource-item">
                      <div tw="relative">
                        <Img
                          fluid={r.image.fluid}
                          tw="rounded-t-lg object-cover h-64"
                        />
                        <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                          {r.category}
                        </div>
                      </div>
                      <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                        <p tw="text-navy">{r.title}</p>
                      </div>
                    </div>
                  </Link>
                )
              ) : (
                <LocalizedLink to={`/resource/${r.uid}`}>
                  <div key={key} className="resource-item">
                    <div tw="relative">
                      <Img
                        fluid={r.image.fluid}
                        tw="rounded-t-lg object-cover h-64"
                      />
                      <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                        {r.category}
                      </div>
                    </div>
                    <div tw="max-w-xs m-auto mt-12 mb-8 h-12">
                      <p tw="text-navy">{r.title}</p>
                    </div>
                  </div>
                </LocalizedLink>
              )
            })}
          </div>
          {more < filteredResourcesList.length ? (
            <div>
              <SmallSolidTealButton tw="mt-8" onClick={() => setMore(more + 3)}>
                {i18n.resources.show_more}
              </SmallSolidTealButton>
            </div>
          ) : (
            false
          )}
        </div>
      </WatchElementAnimate>
      {videoItem != null &&
        videoItem.data.category === 'Videos' &&
        videoItem.data.link.url != '' ? (
        <div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId={videoItem.data.link.url.substring(
              videoItem.data.link.url.lastIndexOf('=') + 1,
              videoItem.data.link.url.length
            )}
            youtube={{
              autoplay: 1,
              mute: 1,
            }}
            onClose={() => {
              setOpen(false)
              setVideoItem(null)
            }}
          />
        </div>
      ) : (
        false
      )}
    </ResourcesWrapper>
  )
}
export default Resources
