import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { useForm } from 'react-hook-form'
import background from '../../images/resources_bg.svg'
import paperPlane from '../../images/paper-plane.svg'
import axios from 'axios'
import { Link } from 'gatsby'
import { SmallSolidTurquoiseInput } from '../../utils/styles'
const BottomFormWrapper = styled.div`
  @media (min-width: 769px) {
    .ebook-image {
      width: 500px;
    }
  }
`

const BottomForm = ({ resources, locale, i18n }) => {
  const { register, handleSubmit } = useForm()
  const [subscribed, setSubscribed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/subscribe', {
      form_id: '108139882',
      email: data.email,
    })
    if (response.data.hasOwnProperty('id')) {
      setSubscribed(true)
    }
    setSubmitting(false)
  }
  const handleMailerLitePopup = () => {
    if (locale === 'en-us') {
      window.ml_account('webforms', '4168069', 'u7f8k1', 'show')
    } else {
      window.ml_account('webforms', '4188439', 'n4d0h1', 'show')
    }
  }
  return (
    <BottomFormWrapper tw="container pt-16 pb-20 text-center md:pb-28">
      <div tw="md:flex md:mt-10 rounded-lg">
        <div
          tw="md:flex-none rounded-t-lg md:rounded-l-lg"
          className="ebook-image">
          <Img
            fluid={resources.data.ebook_form_image.fluid}
            tw="rounded-t-lg md:rounded-l-lg md:rounded-r-none"></Img>
        </div>
        <div tw="bg-air py-14 px-16 md:flex-grow rounded-b-lg md:rounded-r-lg md:rounded-bl-none">
          <div tw="flex flex-col gap-4 text-center">
            <h2 tw="text-navy text-left">
              {!subscribed
                ? resources.data.ebook_form_headline.text
                : 'Thank you!'}
            </h2>
            <p tw="max-w-md text-ocean text-left">
              {!subscribed
                ? resources.data.ebook_form_subheadline.text
                : 'Please check your emails to receive the download link. '}
            </p>

            <div tw="flex flex-col md:flex-row gap-y-4">
              <Link to={resources.data.ebook_form_link.url} target="_blank">
                <SmallSolidTurquoiseInput tw="relative md:text-lg">
                  {i18n.buttons.download_ebook}
                </SmallSolidTurquoiseInput>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BottomFormWrapper>
  )
}
export default BottomForm
